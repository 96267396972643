<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.begin_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Tanggal Mulai"
                    style="width:100%;"
                    format="DD MMMM YYYY"
                    value-format="YYYY-MM-DD"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ADatePicker
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Tanggal Akhir"
                    style="width:100%;"
                    format="DD MMMM YYYY"
                    value-format="YYYY-MM-DD"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterDistributor
                    style="width:100%;"
                    v-model:value="state.params.distributor"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterFungsi
                    style="width:100%;"
                    v-model:value="state.params.fungsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterJenisApi
                    style="width:100%;"
                    v-model:value="state.params.type"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASelect
                    show-search
                    allow-clear
                    placeholder="Pilih Status"
                    style="width:100%"
                    :options="state.statusLists"
                    v-model:value="state.params.status"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    v-model:value="state.params.q"
                    placeholder="Cari ..."
                    allow-clear
                    style="width:100%"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <ASpace>
                    <ATooltip title="Cari">
                        <AButton
                            type="primary"
                            @click="fetchDataList"
                            :loading="state.isFetching">
                            <span
                                v-if="!state.isFetching"
                                class="fa fa-search"
                                aria-hidden="true"/>
                            <span v-else>
                                Memuat Data ...
                            </span>
                        </AButton>
                    </ATooltip>
                    <ATooltip title="Syncron Manual">
                        <AButton
                            type="primary"
                            @click="btnAllSync">
                            <i class="fa fa-refresh" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                </ASpace>
            </ACol>
        </ARow>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
                
                <template #status="{ text }">
                    <ATag v-if="text === 1" color="#108ee9">Sukses</ATag>
                    <ATag v-else color="grey">Gagal</ATag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            title="Syncron"
                            placement="topRight">
                            <AButton
                                class="button"
                                size="small"
                                :disabled="!record.is_open"
                                :loading="record.isSync"
                                @click="btnSyncrons(record)">
                                <i class="fa fa-refresh" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            title="Log History"
                            placement="topRight">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnLogHistoryList(record)">
                                <i class="fa fa-history" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- live stock -->
        <LogHistoryList
            v-if="visibleLogHistoryListModal"
            v-model:visible="visibleLogHistoryListModal"
            v-model:item="visibleLogHistoryListItemModal"/>

        <!-- form sync manual -->
        <AllSync
            v-if="visibleAllSyncModal"
            v-model:visible="visibleAllSyncModal"
            @success="fetchDataList"/>
        
        <!-- message errors -->
        <MessageErrors
            v-model:visible="state.errors.visible"
            :data="state.errors.data"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, h } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterFungsi from '@/components/filter/FilterFungsi'
import FilterJenisApi from '@/components/filter/FilterJenisApi'
import MessageErrors from '@/components/Molecules/MessageErrors'
import moment from 'moment'
import AllSync from './AllSync'
import LogHistoryList from './LogHistoryList'
import {
    hasRoles,
    hasPermissions,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'
import { includes } from 'lodash'
import useUserInfo from '@/composables/useUserInfo'

export default defineComponent({
    components: {
        MessageErrors,
        FilterDistributor,
        FilterFungsi,
        FilterJenisApi,
        AllSync,
        LogHistoryList,
    },
    setup() {
        const { isDistributor, vendor_id } = useUserInfo()
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Tanggal Scheduler',
                    dataIndex: 'trans_date',
                    format: 'DD MMMM YYY',
                },
                {
                    title: 'Fungsi',
                    dataIndex: 'function',
                },
                {
                    title: 'Tipe',
                    dataIndex: 'type',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'vendor_code',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'vendor_name',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Updated Date',
                    dataIndex: 'time',
                },
                {
                    title: 'Total Data',
                    dataIndex: 'data_count',
                },
                {
                    title: 'Action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/synchrons',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                begin_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()),
                distributor: [],
                type: [],
                fungsi: [],
                status: null,
                page: 1,
                "per-page": 10,
            }),
            errors: {
                visible: false,
                data: [],
            },
            statusLists: [
                {
                    label: "Sukses",
                    value: 1,
                },
                {
                    label: "Gagal",
                    value: 0,
                },
            ],
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.begin_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.begin_date) {
                state.params.begin_date = moment(state.params.begin_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    items.forEach((item) => {
                        item.isSync = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnSyncrons = (record) => {
            Modal.confirm({
                title: 'Konfirmasi Syncron Data',
                content: 'Apakah Anda ingin syncron data transaksi?',
                onOk() {
                    record.isSync = true
                    apiClient.post(`/api/synchrons/${record.id}/resynch`, { date: record.trans_date })
                        .then(({ data }) => {
                            fetchDataList()
                            message.success('Berhasil disimpan')
                        })
                        .catch(e => message.error('Gagal syncron!'))
                        .finally(() => {
                            record.isSync = false
                        })
                },
                onCancel() { },
            })
        }

        // handle form all sync
        const visibleAllSyncModal = ref(false);
        const btnAllSync = () => {
            visibleAllSyncModal.value = true
        }

        // handle live stock list
        const visibleLogHistoryListModal = ref(false);
        const visibleLogHistoryListItemModal = ref(null);

        const btnLogHistoryList = (item = null) => {
            visibleLogHistoryListModal.value = true
            visibleLogHistoryListItemModal.value = item
        }

        // handle vue
        onMounted(() => {
            // validation roles default params
            if (isDistributor.value) {
                state.params.distributor.push(vendor_id.value)
            }

            fetchDataList()
        })

        return {
            state,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            queryParams,
            fetchDataList,
            handleTableChange,
            btnSyncrons,
            btnLogHistoryList,
            visibleLogHistoryListModal,
            visibleLogHistoryListItemModal,
            btnAllSync,
            visibleAllSyncModal,
            // variable
            vendor_id,
            // only variable role
            hasRoles,
            hasPermissions,
            ROLE_ADMIN_BK,
        }
    },
});
</script>
