<template>
    <AModal
        :visible="visible"
        title="Log History"
        @cancel="handleModalCancel"
        :destroy-on-close="true"
        width="70%"
        :footer="null">
        
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #status="{ text }">
                    <ATag v-if="text === 1" color="#108ee9">Sukses</ATag>
                    <ATag v-else color="grey">Gagal</ATag>
                </template>

                <template #expandedRowRender="{ index }">
                    <md-table
                        :columns="state.columnsChildren"
                        :data-source="[state.data[index]]"
                        :pagination="false">
                    </md-table>
                </template>

            </MdTable>
        </div>
    </AModal>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import { Modal, message } from 'ant-design-vue'
import { pickBy } from 'lodash'
import moment from 'moment'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

export default defineComponent({
    components: {
        // 
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'Tgl Trans',
                    dataIndex: 'transdate',
                    customRender: ({ text }) => moment(text).format('DD MMMM YYYY'),
                },
                {
                    title: 'Type',
                    dataIndex: 'type',
                },
                {
                    title: 'Tgl Sync',
                    dataIndex: 'time',
                    customRender: ({ text }) => moment(text).format('DD MMMM YYYY H:mm:ss'),
                },
                {
                    title: 'Note',
                    dataIndex: 'note',
                },
                {
                    title: 'User',
                    dataIndex: 'user',
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Count Data',
                    dataIndex: 'data_count',
                },
                {
                    title: 'Duration',
                    dataIndex: 'duration',
                },
            ],
            columnsChildren: [
                {
                    title: 'Message',
                    dataIndex: 'message',
                },
            ],
            endpoint: {
                current: `/api/synchrons/${props.item.id}/detail`,
            },
            isFetching: false,
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            params: {
                q: '',
                date: props.item.trans_date,
                page: 1,
                "per-page": 10,
            },
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true

            apiClient
                .get(state.endpoint.current, {
                    params: pickBy(state.params),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        onMounted(() => {
            fetchDataList()
        })

        return {
            state,
            errorMessage,
            handleModalCancel,
            handleTableChange,
            fetchDataList,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
